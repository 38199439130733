import { render, staticRenderFns } from "./extension.vue?vue&type=template&id=febd7f1e&scoped=true&"
import script from "./extension.vue?vue&type=script&lang=js&"
export * from "./extension.vue?vue&type=script&lang=js&"
import style0 from "./extension.vue?vue&type=style&index=0&id=febd7f1e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "febd7f1e",
  null
  
)

export default component.exports